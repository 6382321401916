export const HTTP_RESPONSE = Object.freeze({
  OK: { code: 200, statusText: 'Ok' },
  CREATED: { code: 201, statusText: 'Created' },
  UPDATED: { code: 204, statusText: 'Updated' },
  NOT_FOUND: { code: 404, statusText: 'Not Found' },
  BAD_REQUEST: { code: 400, statusText: 'Bad Request' },
  NOT_AUTHORIZED: { code: 401, statusText: 'Not Authorized' },
  CONFLICTS: { code: 409, statusText: 'Conflict' },
  INTERNAL_SERVER_ERROR: { code: 500, statusText: 'Internal Server Error' },
  FAILED_DEPENDENCY: { code: 424, statusText: 'Failed Dependency' },
  GONE: { code: 410, statusText: 'Gone' }
});

export const followerCountOptions = [
  {
    value: "0-1000",
    fieldLabel: "0-1,000",
  },
  {
    value: "1000-5000",
    fieldLabel: "1,000-5,000",
  },
  {
    value: "5000-10000",
    fieldLabel: "5,000-10,000",
  },
  {
    value: "10000-20000",
    fieldLabel: "10,000-20,000",
  },
  {
    value: "20000-40000",
    fieldLabel: "20,000-40,000",
  },
  {
    value: "40000-60000",
    fieldLabel: "40,000-60,000",
  },
  {
    value: "60000-80000",
    fieldLabel: "60,000-80,000",
  },
  {
    value: "80000-100000",
    fieldLabel: "80,000-100,000",
  },
  {
    value: "100000-150000",
    fieldLabel: "100,000-150,000",
  },
  {
    value: "150000-200000",
    fieldLabel: "150,000-200,000",
  },
  {
    value: "200000-250000",
    fieldLabel: "200,000-250,000",
  },
  {
    value: "250000-300000",
    fieldLabel: "250,000-300,000",
  },
  {
    value: "300000-400000",
    fieldLabel: "300,000-400,000",
  },
  {
    value: "400000-500000",
    fieldLabel: "400,000-500,000",
  },
  {
    value: "500000-750000",
    fieldLabel: "500,000-750,000",
  },
  {
    value: "750000-1000000",
    fieldLabel: "750,000-1,000,000",
  },
  {
    value: "1000000-3000000",
    fieldLabel: "1,000,000-3,000,000",
  },
  {
    value: "3000000-5000000",
    fieldLabel: "3,000,000-5,000,000",
  },
  {
    value: "5000000-7000000",
    fieldLabel: "5,000,000-7,000,000",
  },
  {
    value: "7000000-10000000",
    fieldLabel: "7,000,000-10,000,000",
  },
  {
    value: "10000000-20000000",
    fieldLabel: "10,000,000-20,000,000",
  },
  {
    value: "20000000-1000000000",
    fieldLabel: "20,000,000+",
  },
];

export const socialMediaOptions = [
  {
    value: "Tiktok Video",
    fieldLabel: "TikTok Video"
  },
  {
    value: "Tiktok Live",
    fieldLabel: "TikTok Live"
  },
  {
    value: "Instagram Post",
    fieldLabel: "Instagram Post"
  },
  {
    value: "Instagram Reels",
    fieldLabel: "Instagram Reels"
  },
  {
    value: "Instagram Stories",
    fieldLabel: "Instagram Stories"
  },
  {
    value: "Youtube Video",
    fieldLabel: "YouTube Video"
  },
  {
    value: "Youtube Shorts",
    fieldLabel: "YouTube Shorts"
  },
  {
    value: "Twitter Tweet",
    fieldLabel: "Twitter Tweet"
  },
  {
    value: "Facebook Post",
    fieldLabel: "Facebook Post"
  },
  {
    value: "Facebook Video",
    fieldLabel: "Facebook Video"
  },
  {
    value: "Pinterest Pin",
    fieldLabel: "Pinterest Pin"
  },
  {
    value: "Snapchat Post",
    fieldLabel: "Snapchat Post"
  },
  {
    value: "LinkedIn Post",
    fieldLabel: "LinkedIn Post"
  },
  {
    value: "Twitch Livestream",
    fieldLabel: "Twitch Livestream"
  },
  {
    value: "UGC Video",
    fieldLabel: "UGC Video"
  },
  {
    value: "UGC Photo",
    fieldLabel: "UGC Photo"
  }
];

export const actionArray = [
  {
    value: 'creator-profile',
    fieldLabel: 'View Creator Profile',
  },
  {
    value: 'edit-profile',
    fieldLabel: 'Edit Profile',
  },
  {
    value: 'approve',
    fieldLabel: 'Approve',
  },
  {
    value: 'reject',
    fieldLabel: 'Reject',
  },
  {
    value: 'delete',
    fieldLabel: 'Delete',
  },
  {
    value: 'assign-the-brand',
    fieldLabel: 'Assign the Brand',
  },
  {
    value: 'brand-profile',
    fieldLabel: 'View Brand Profile',
  },
  {
    value: 'deactivate',
    fieldLabel: 'Deactivate',
  },
  {
    value: 'approve',
    fieldLabel: 'Activate',
  },
];

export const selectOptions = [
  { fieldLabel: "Image", value: "Image" },
  { fieldLabel: "Video", value: "Video" },
  { fieldLabel: "Press/Media Feature", value: "Press/Media Feature" },
];

export const ALL_SOCIAL_MEDIA = {
  "Tiktok Video": "Tiktok Video",
  "Instagram Post": "Instagram Post",
  "Instagram Reels": "Instagram Reels",
  "Instagram Stories": "Instagram Stories",
  "Youtube Video": "Youtube Video",
  "Youtube Shorts": "Youtube Shorts",
  "Twitter Tweet": "Twitter Tweet",
  "Facebook Post": "Facebook Post",
  "Facebook Video": "Facebook Video",
  "Pinterest Pin": "Pinterest Pin",
  "Snapchat Post": "Snapchat Post",
  "LinkedIn Post": "LinkedIn Post",
  "Twitch Livestream": "Twitch Livestream",
  "Tiktok Live": "Tiktok Live",
  "UGC Video": "UGC Video",
  "UGC Photo": "UGC Photo"
};