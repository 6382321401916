import { ReducerActionProps } from '../../../../shared/utilities/interfaces';
import {
  GET_ADMIN_DASHBOARD_DATA,
  GET_CREATOR_LIST,
  GET_CREATOR_LIST_FAILURE,
  SET_USERS_COUNT,
  GET_BRAND_LIST,
  GET_BRAND_LIST_FAILURE,
  SET_BRAND_COUNT,
  GET_REVIEW_LIST,
  SET_REVIEW_COUNT,
  GET_REVIEW_LIST_FAILURE
} from './actions';

const initialState = {
  // add initial state variables
  userCount: 0,
  creatorList: [],
  brandCount: 0,
  keys:[],
  brandList: [],
  reviewCount: 0,
  reviewList: [],
  defaultList: {
    data: [
      {
        ID: '87346734',
        Date: '22-09-2021',
        Amount: '1,780',
        Action: '',
      },
      {
        ID: '6782364',
        Date: '22-09-2021',
        Amount: '1,780',
        Action: '',
      },
      {
        ID: '7986732',
        Date: '22-09-2021',
        Amount: '1,780',
        Action: '',
      }
    ]
  }
};

const adminDashboardReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload
      };

    case GET_CREATOR_LIST:
      return {
        ...state,
        creatorList: action.payload,
        keys: action.payload.keys
      };

    case GET_CREATOR_LIST_FAILURE:
      return {
        ...state,
        creatorList: []
      };

    case SET_USERS_COUNT:
      return {
        ...state,
        userCount: action.payload,
      };

    case GET_BRAND_LIST:
      return {
        ...state,
        brandList: action.payload.data,
        keys: action.payload.keys
      };  
      
    case GET_BRAND_LIST_FAILURE:
      return {
        ...state,
        brandList: []
      };

    case SET_BRAND_COUNT:
      return {
        ...state,
        brandCount: action.payload,
      };
    case GET_REVIEW_LIST:
      return {
        ...state,
        reviewList: action.payload,
        keys: action.payload.keys

      };

    case GET_REVIEW_LIST_FAILURE:
      return {
        ...state,
        reviewList: []
      };

    case SET_REVIEW_COUNT:
      return {
        ...state,
        reviewCount: action.payload,
      };
    default:
      return {
        ...state
      };
  }
};

export default adminDashboardReducer;
