/* eslint-disable no-unused-vars */
export enum SessionKeys {
  accessToken = 'accessToken',
  userId = 'userId',
  brandId = 'brandId',
  refreshToken = 'refreshToken',
  accountStatus = 'accountStatus',
  accountType = 'accountType',
  userData = 'userData',
}

export enum AuthRoles {
  BRAND = 'BRAND',
  CREATOR = 'CREATOR',
  ADMIN = 'ADMIN'
}

export enum AccountTypes {
  BRAND = 'BRAND',
  CREATOR = 'CREATOR'
}

export enum SocialMediaTypes {
  Tiktok = 'Tiktok',
  Instagram = 'Instagram',
  Youtube = 'Youtube',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Pinterest = 'Pinterest',
  Snapchat = 'Snapchat',
}

export type AccountType = 'CREATOR' | 'BRAND' | 'ADMIN';