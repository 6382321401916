/* eslint-disable no-unused-vars */
import { AxiosResponse } from 'axios';
import { CORE_ACTIONS } from 'core/store/app/actions';
// import { AUTH_ACTIONS } from "features/auth/store/actions";
import {
  addPortfolioRequest,
  getSingleCreator,
  updateSingleCreator,
  editPortfolioRequest,
  getCountries,
  uploadPortfolioImage,
  getPortfolioByID,
  getOtherCreatorProfle,
  uploadProfileImage,
} from '../../../../features/creator/profile/store/api';
import { getAlertType } from 'shared/helpers';
import { AppDispatch, history } from '../../../../store/store';
import { getFromSession } from '../../../../shared/helpers/auth.helper';
import { SessionKeys, AuthRoles } from '../../../../core/constants';

const accountType = getFromSession(SessionKeys.accountType).result;

export enum CREATOR_PROFILE_ACTIONS {
  RESET_PROFILE_PIC_SUCCESS = 'RESET_PROFILE_PIC_SUCCESS',
  GET_OTHER_PROFILE_DATA_SUCCESS = 'GET_OTHER_PROFILE_DATA_SUCCESS',
  UPDATE_CREATOR_PROFILE_DATA = 'UPDATE_PROFILE_DATA',
  GET_PROFILE_DATA_SUCCESS = 'GET_PROFILE_DATA_SUCCESS',
  GET_PROFILE_DATA_REQUEST = 'GET_PROFILE_DATA_REQUEST',
  GET_PROFILE_DATA_FAILURE = 'GET_PROFILE_DATA_FAILURE',
  GET_COUNTRIES_DATA_REQUEST = 'GET_COUNTRIES_DATA_REQUEST',
  GET_COUNTRIES_DATA_SUCCESS = 'GET_COUNTRIES_DATA_SUCCESS',
  SET_PROFILE_DATA_SUCCESS = 'SET_PROFILE_DATA_SUCCESS',
  SET_PROFILE_DATA_FAILURE = 'SET_PROFILE_DATA_FAILURE',
  SET_PROFILE_PIC_SUCCESS = 'SET_PROFILE_PIC_SUCCESS',
}

export enum CREATOR_PORTFOLIO_ACTIONS {
  RESET_PORTFOLIO_PIC_SUCCESS = 'RESET_PORTFOLIO_PIC_SUCCESS',
  ADD_PORTFOLIO_REQUEST = 'ADD_PORTFOLIO_REQUEST',
  ADD_PORTFOLIO_SUCCESS = 'ADD_PORTFOLIO_SUCCESS',
  ADD_PORTFOLIO_FAILURE = 'ADD_PORTFOLIO_FAILURE',
  SET_PORTFOLIO_IMAGE_SUCCESS = '"SET_PORTFOLIO_IMAGE_SUCCESS',
  GET_PORTFOLIO_DATA_REQUEST = '"GET_PORTFOLIO_DATA_REQUEST',
  GET_PORTFOLIO_DATA_FAILURE = '"GET_PORTFOLIO_DATA_FAILURE',
  SET_PORTFOLIO_DATA_SUCCESS = '"SET_PORTFOLIO_DATA_SUCCESS',
  UPDATE_PORTFOLIO_DATA_SUCCESS = 'UPDATE_PORTFOLIO_DATA_SUCCESS',
}

export function GetCreatorProfileAction(userId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_REQUEST });
    try {
      const response: AxiosResponse = await getSingleCreator(userId);
      const { data } = response.data;
      dispatch({
        type: CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_SUCCESS,
        payload: {
          selectedUser: data,
        },
      });
    } catch (error) {
      dispatch({
        type: CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_FAILURE,
      });
    }
  };
}

export function GetCountriesAction() {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: CREATOR_PROFILE_ACTIONS.GET_COUNTRIES_DATA_REQUEST });
    try {
      const response: AxiosResponse = await getCountries();
      const { data } = response.data;
      dispatch({
        type: CREATOR_PROFILE_ACTIONS.GET_COUNTRIES_DATA_SUCCESS,
        payload: {
          countries: data,
        },
      });
    } catch (error) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: error.response.data.message,
          severity: getAlertType(error.response.data.code),
          alertTitle: error.response.data.error,
        },
      });
    }
  };
}

export function GetOtherCreatorProfileAction(
  userId: string,
  searchUserID?: string
) {
  return async (dispatch: AppDispatch) => {
    // perform actions
    dispatch({ type: CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_REQUEST });
    try {
      const response: AxiosResponse = await getOtherCreatorProfle(
        userId,
        searchUserID
      );
      const { data } = response.data;
      dispatch({
        type: CREATOR_PROFILE_ACTIONS.GET_OTHER_PROFILE_DATA_SUCCESS,
        payload: {
          otherCreatorData: data,
        },
      });
    } catch (error) {
      dispatch({
        type: CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_FAILURE,
      });
    }
  };
}

export function UpdateCreatorProfileAction(
  role: string,
  userId: string,
  body: any
) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await updateSingleCreator(role, userId, body);
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      if(accountType === AuthRoles.ADMIN) {
        history.push(`/creator-profile/other/${userId}`);
      } else {
        history.push('/creator-profile');
      }
    } catch (error) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: error.response.data.message,
          severity: getAlertType(error.response.data.code),
          alertTitle: error.response.data.error,
        },
      });
    }
  };
}

export function UpdateWithImageAction(userId, formData: any) {
  const body = formData;
  return async (dispatch: AppDispatch | any) => {
    try {
      const response = await uploadProfileImage(userId, body);
      const ima = response.data.data.url;
      dispatch({
        type: CREATOR_PROFILE_ACTIONS.SET_PROFILE_PIC_SUCCESS,
        payload: {
          profileImage: ima,
        },
      });
    } catch (error) {
      if(error.message.toLowerCase() === "network error"){
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: "Profile image size should be less than or equal to 5MB.",
            severity: getAlertType(400),
            alertTitle: "Error",
          },
        });
      } else {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: error.data ? error.data.message : error.message,
            severity: error.data ? getAlertType(error.data.code) : 'error',
            alertTitle: error?.data?.statusText,
          },
        });
      }
    }
  };
}

// eslint-disable-next-line max-len
export function addCreatorPortfolioAction(userId: string, payload: any) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_PORTFOLIO_ACTIONS.ADD_PORTFOLIO_REQUEST });
    try {
      // eslint-disable-next-line max-len
      const response: AxiosResponse = await addPortfolioRequest(
        userId,
        payload
      );
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      dispatch({
        type: CREATOR_PORTFOLIO_ACTIONS.ADD_PORTFOLIO_SUCCESS,
        payload: {
          status: response.data.data.status,
        },
      });
      history.push('/creator-profile');
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error,
        },
      });
      dispatch({ type: CREATOR_PORTFOLIO_ACTIONS.ADD_PORTFOLIO_FAILURE });
    }
  };
}

export function AddPortfolioImageAction(userId, formData: any) {
  const body = formData;
  return async (dispatch: AppDispatch | any) => {
    try {
      const response = await uploadPortfolioImage(userId, body);
      const ima = response.data.data.url;
      dispatch({
        type: CREATOR_PORTFOLIO_ACTIONS.SET_PORTFOLIO_IMAGE_SUCCESS,
        payload: {
          addPortfolioImage: ima,
        },
      });
    } catch (error) {
      if(error.message.toLowerCase() === "network error") {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: "Portfolio image size should be less than or equal to 5MB.",
            severity: getAlertType(400),
            alertTitle: "Error",
          },
        });
      } else {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: error.data.message,
            severity: getAlertType(error.data.code),
            alertTitle: error.data.statusText,
          },
        });
      }
    }
  };
}

export function UpdateCreatorPortfolioAction(
  userId: string,
  portfolioId: string,
  body: any
) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await editPortfolioRequest(userId, portfolioId, body);
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      history.push('/creator-profile');
    } catch (error) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: error.response.data.message,
          severity: getAlertType(error.response.data.code),
          alertTitle: error.response.data.error,
        },
      });
    }
  };
}
export function GetPortfolioDataAction(userId: string, portfolioId?: string) {
  return async (dispatch: AppDispatch) => {
    // perform actions
    dispatch({ type: CREATOR_PORTFOLIO_ACTIONS.GET_PORTFOLIO_DATA_REQUEST });
    try {
      const response: AxiosResponse = await getPortfolioByID(
        userId,
        portfolioId
      );
      const { data } = response.data;
      dispatch({
        type: CREATOR_PORTFOLIO_ACTIONS.SET_PORTFOLIO_DATA_SUCCESS,
        payload: {
          portfolioUser: data,
        },
      });
    } catch (error) {
      dispatch({
        type: CREATOR_PORTFOLIO_ACTIONS.GET_PORTFOLIO_DATA_FAILURE,
      });
    }
  };
}

export function ResetProfilePicAction() {
  return async (dispatch: AppDispatch | any) => {
    dispatch({
      type: CREATOR_PROFILE_ACTIONS.RESET_PROFILE_PIC_SUCCESS
    });
  };
}

export function ResetPortfolioPicAction() {
  return async (dispatch: AppDispatch | any) => {
    dispatch({
      type: CREATOR_PORTFOLIO_ACTIONS.RESET_PORTFOLIO_PIC_SUCCESS
    });
  };
}