import axiosInstance from '../../../../core/network';

export function getCreatorList(searchString:string, page) {
  const url = searchString.length >= 3 ? `users/user/creator${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `users/user/creator${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};

export function getBrandListAPI(searchString, page) {
  const url = searchString.length >= 3 ? `users/user/brand${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `users/user/brand${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};

export function updateUserStatusAPI(status, userId) {
  const url = `/users/update-status`;
  return axiosInstance.put(url, { status, userId });
};
export function updateReviewStatusAPI(status, userId, reviewId) {
  const url = `brand/:${userId}/update-review-status`;
  return axiosInstance.put(url, { status, reviewId });
};

export function deleteReviewAPI(userId, reviewId) {
  const url = `/brand/${userId}/${reviewId}`;
  return axiosInstance.delete(url);
};

export function getReviewsAPI(searchString, page, userId) {
  const url = searchString.length >= 3 ? `brand/${userId}/reviews/list${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `brand/${userId}/reviews/list${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};

export function assignUserAPI(userId: string, brandId: string) {
  const url = `users/assign-brand`;
  return axiosInstance.put(url,{userId,brandId});
};