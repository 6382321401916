import { AUTH_ACTIONS } from './actions';
import {
  IAuth,
  ReducerActionProps
} from '../../../shared/utilities/interfaces';
import { getFromSession } from '../../../shared/helpers/auth.helper';
import { SessionKeys } from '../../../core/constants';

function isUserLoggedIn() {
  const accessToken = getFromSession(SessionKeys.accessToken).success;
  const userId = getFromSession(SessionKeys.userId).success;
  return userId && accessToken;
}

function getSessionValue(key: string) {
  const { result, success } = getFromSession(key);
  return success ? result : '';
}

function getSessionUserData() {
  const { result, success } = getFromSession(SessionKeys.userData);
  return success ? JSON.parse(result) : {
    name: 'User',
    email: '',
    firstName: '',
    lastName: ''
  };
}

const initialState: IAuth = {
  status: getSessionValue(SessionKeys.accountStatus),
  isLoggedIn: isUserLoggedIn(),
  accessToken: getSessionValue(SessionKeys.accessToken),
  refreshToken: getSessionValue(SessionKeys.refreshToken),
  userId: getSessionValue(SessionKeys.userId),
  sessionExpired: null,
  accountType: getSessionValue(SessionKeys.accountType),
  userData: getSessionUserData(),
};

const AuthReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case AUTH_ACTIONS.SIGNUP_REQUEST: {
      return {
        ...state,
      };
    }

    case AUTH_ACTIONS.SIGNUP_FAILURE: {
      return {
        ...state,
      };
    }

    case AUTH_ACTIONS.SIGNUP_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case AUTH_ACTIONS.LOGIN_REQUEST:
    case AUTH_ACTIONS.LOGIN_FAILURE: {
      return state;
    }

    case AUTH_ACTIONS.LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoggedIn: !!(action.payload.accessToken && action.payload.userId)
      };
    }

    case AUTH_ACTIONS.LOGOUT_REQUEST: {
      return {};
    }

    case AUTH_ACTIONS.SESSION_EXPIRED: {
      return {
        ...state,
        sessionExpired: true,
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default AuthReducer;