import { AuthRoles } from '../core/constants';
import { lazy } from 'react';

export const creatorRoutes = [
  {
    path: "/dashboard",
    exact: true,
    authRole: AuthRoles.CREATOR,
    component: lazy(() => import("../features/creator/dashboard/container")),
  },
  {
    path: "/brands",
    exact: false,
    authRole: AuthRoles.CREATOR,
    component: lazy(() => import("../features/creator/brands/container")),
  },
  {
    path: "/creator-profile",
    exact: false,
    authRole: AuthRoles.CREATOR,
    component: lazy(() => import("../features/creator/profile/container")),
  }
];

export const brandRoutes = [
  {
    path: '/brand-profile/:brandId',
    exact: false,
    authRole: AuthRoles.BRAND,
    component: lazy(() => import("../features/brand/profile/container")),
  },
  {
    path: "/creator-profile",
    exact: false,
    authRole: AuthRoles.BRAND,
    component: lazy(() => import("../features/creator/profile/container")),
  }
];

export const adminRoutes = [
  {
    path: "/manage-creators",
    exact: true,
    authRole: AuthRoles.ADMIN,
    component: lazy(() => import("../features/admin/dashboard/container")),
  },
  {
    path: "/manage-brands",
    exact: false,
    authRole: AuthRoles.ADMIN,
    component: lazy(() => import("../features/admin/brandList/container")),
  },
  {
    path: "/creator-profile",
    exact: false,
    authRole: AuthRoles.ADMIN,
    component: lazy(() => import("../features/creator/profile/container")),
  },
  {
    path: '/brand-profile/:brandId',
    exact: false,
    authRole: AuthRoles.ADMIN,
    component: lazy(() => import("../features/brand/profile/container")),
  },
  {
    path: "/manage-reviews",
    exact: true,
    authRole: AuthRoles.ADMIN,
    component: lazy(() => import("../features/admin/reviews/container")),
  },
];

export const openRoutes = [
  {
    path: "/auth",
    exact: false,
    component: lazy(() => import("../features/auth/container")),
  },
  {
    path: "/terms-and-conditions",
    exact: false,
    component: lazy(() => import("../features/auth/components/tnc")),
  },
  {
    path: "/privacy-policy",
    exact: false,
    component: lazy(() => import("../features/auth/components/privacyPolicy")),
  },
  {
    path: "/community-guidelines",
    exact: false,
    component: lazy(() => import("../features/auth/components/communityGuidelines")),
  },
  {
    path: "/do-not-sell-my-info",
    exact: false,
    component: lazy(() => import("../features/auth/components/doNotSellMyInfo")),
  },
];
