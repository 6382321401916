import { SessionKeys } from 'core/constants';
import axiosInstance from 'core/network';
import { getFromSession } from 'shared/helpers/auth.helper';


export function getRecentReviews() {
	return axiosInstance.get(
		`brand/${getFromSession(SessionKeys.userId).result}/brand/review?limit=5`
	);
}
