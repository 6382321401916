/* eslint-disable no-unused-vars */
export interface LoginFormBody {
  email: string;
  password: string;
}

export interface SocialMediaObject {
  socialMediaName: string;
  link: string;
  userName?: string;
  noOfFollowers?: string;
}

export type AccountType = 'BRAND' | 'CREATOR' | 'ADMIN';

export interface CreatorSignUpFormBody {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  socialMedia?: SocialMediaObject[];
}

export interface BrandSignUpFormBody {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  brandName: string;
  password: string;
}

export interface ResetPasswordFormBody {
  email: string
}

export enum AccountStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED'
}

export interface UpdatePasswordFromBody {
  password: string;
  token: string;
}

export function getMobileOperatingSystem() {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}
