import { AlertColor } from '@mui/material';

export const getAlertType = (code: number): AlertColor => {
  switch (code) {
    case 200:
    case 201:
    case 204:
      return 'success';

    case 404:
    case 400:
    case 401:
    case 403:
    case 500:
    case 409:
      return 'error';
    default:
      return 'success';
  }
};

export const getDateHumanReadable = (date: string) => {
  const dateArr = new Date(date).toDateString().split(' ');
  dateArr.shift();
  // eslint-disable-next-line prefer-destructuring
  const temp = dateArr[1];
  // eslint-disable-next-line prefer-destructuring
  dateArr[1] = dateArr[0];
  dateArr[0] = temp;
  return dateArr.join(' ');
};
