import { Typography } from '@mui/material';
import React from 'react';
import {connect} from 'react-redux';
import { IAuth, IState } from 'shared/utilities/interfaces';
interface IFooter {
	auth: IAuth;
}

function Footer(footerProps: IFooter) {
	const { isLoggedIn, status } = footerProps.auth ;
	if (isLoggedIn && status === 'APPROVED') {
	return (
		<footer>
			<Typography
				variant='body2'
				sx={{
					textAlign: 'center',
					p: '0.75rem',
					color: '#1A1A1A'
				}}
				style={{
					bottom: 0,
					backgroundColor: 'white',
				}}
			>
				© 2021 Clara
			</Typography>
		</footer>
		);
	} else {
		return <></>;
	}
};

export default connect(
	(state: IState) => ({
		app: state.app,
		auth: state.auth
	})
)(Footer);
