/* eslint-disable no-unused-vars */
import { AxiosError, AxiosResponse } from 'axios';

import { CORE_ACTIONS } from '../../../core/store/app/actions';
import { getAlertType } from '../../../shared/helpers';
import { AccountTypes, AuthRoles, SessionKeys } from '../../../core/constants';
import {
  clearLocalStorage,
  clearSessionStorage,
  setSessionItem,
  // setSessionItem
} from '../../../shared/helpers/auth.helper';
import { loginRequest, resetPasswordRequest, signUpRequest, updatePasswordRequest } from './api';
import { AppDispatch, history } from '../../../store/store';
import { AccountStatusEnum } from '../utilites';
import { HTTP_RESPONSE } from '../../../shared/utilities/constants';

export enum AUTH_ACTIONS {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  SIGNUP_REQUEST = 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE = 'SIGNUP_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
}

export function createAccountAction(payload: any, type: string) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: AUTH_ACTIONS.SIGNUP_REQUEST });
    try {
      const response: AxiosResponse = await signUpRequest(payload, type);
      setSessionItem(SessionKeys.accountType, type);
      setSessionItem(SessionKeys.accountStatus, response.data.data.status);
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText
        }
      });
      dispatch({
        type: AUTH_ACTIONS.SIGNUP_SUCCESS, payload: {
          status: response.data.data.status
        }
      });
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: AUTH_ACTIONS.SIGNUP_FAILURE });
    }
  };
}

export function loginAction(payload: any) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: AUTH_ACTIONS.LOGIN_REQUEST });
    try {
      const response: AxiosResponse = await loginRequest(payload);
      const {
        code,
        message,
        statusText
      } = response.data;
      if (code === HTTP_RESPONSE.NOT_AUTHORIZED.code) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: message,
            severity: getAlertType(code),
            alertTitle: statusText
          }
        });
      }
      const {
        data: {
          token,
          userId,
          brandId,
          refreshToken,
          status,
          role,
          email,
          firstName,
          lastName
        }
      } = response.data;
      const userData = {
        name: firstName + ' ' + lastName,
        email: email,
        firstName: firstName,
        lastName: lastName
      };
      setSessionItem(SessionKeys.accessToken, token);
      setSessionItem(SessionKeys.userId, userId);
      setSessionItem(SessionKeys.refreshToken, refreshToken);
      setSessionItem(SessionKeys.accountStatus, status);
      setSessionItem(SessionKeys.accountType, role);
      setSessionItem(SessionKeys.userData, JSON.stringify(userData));
      dispatch({
        type: AUTH_ACTIONS.LOGIN_SUCCESS,
        payload: {
          accessToken: token,
          refreshToken: refreshToken,
          status: status,
          accountType: role,
          userId: userId,
          brandId,
          userData
        }
      });
      if (status === 'APPROVED') {
        if (role === AuthRoles.BRAND) {
          // keep edit profile for now as only that page is done
          // please replace later with /brand-profile
          setSessionItem(SessionKeys.brandId, brandId);
          history.replace(`/brand-profile/${brandId}`);
        } else if (role === AuthRoles.CREATOR) {
          history.replace('/dashboard');
        } else if (role === AuthRoles.ADMIN) {
          history.replace('/manage-creators');
        }
      } else {
        history.push('/auth/signup');
      }
      return {
        token,
        status,
        role,
        success: true
      };
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: AUTH_ACTIONS.LOGIN_FAILURE });
      return {
        success: false
      };
    }
  };
}

export function logoutAction() {
  return (dispatch: AppDispatch) => {
    clearSessionStorage();
    clearLocalStorage();
    dispatch({ type: AUTH_ACTIONS.LOGOUT_REQUEST });
    history.replace('/auth');
  };
}

export function sessionExpiredAction() {
  return (dispatch: AppDispatch | any) => {
    dispatch({ type: AUTH_ACTIONS.SESSION_EXPIRED });
    // dispatch({
    //   type: CORE_ACTIONS.ENABLE_MODAL,
    //   payload: {
    //     message: 'Please login again',
    //     severity: 'error',
    //     alertTitle: 'Session expired'
    //   }
    // });
    dispatch(logoutAction());
  };
}

export function resetPasswordAction(payload: any) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD_REQUEST });
    try {
      const response: AxiosResponse = await resetPasswordRequest(payload);
      const {
        data
      } = response;
      if (data.code === HTTP_RESPONSE.OK.code) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code),
            alertTitle: response.data.statusText
          }
        });
      }
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD_FAILURE });
    }
  };
}

export function updatePasswordAction(payload: any) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD_REQUEST });
    try {
      const response: AxiosResponse = await updatePasswordRequest(payload);
      const {
        data
      } = response;
      if (data.code === HTTP_RESPONSE.OK.code) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code),
            alertTitle: response.data.statusText
          }
        });
        history.replace('/auth');
      }
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
    }
  };
}