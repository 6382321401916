import React from 'react';

const Brand = ({ iconColor, ...props }) => (
  <svg {...props} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.0996 45.1375C19.6621 45.1375 9.53711 35.0125 9.53711 22.575C9.53711 10.125 19.6621 0 32.0996 0C44.5371 0 54.6621 10.125 54.6621 22.5625C54.6621 35.0125 44.5371 45.1375 32.0996 45.1375ZM32.0996 10.5125C25.4496 10.5125 20.0371 15.925 20.0371 22.575C20.0371 29.225 25.4496 34.625 32.0996 34.625C38.7496 34.625 44.1621 29.2125 44.1621 22.575C44.1621 15.9125 38.7496 10.5125 32.0996 10.5125Z" fill={iconColor} />
    <path d="M32.0992 31.2741C36.911 31.2741 40.8117 27.3734 40.8117 22.5616C40.8117 17.7498 36.911 13.8491 32.0992 13.8491C27.2874 13.8491 23.3867 17.7498 23.3867 22.5616C23.3867 27.3734 27.2874 31.2741 32.0992 31.2741Z" fill={iconColor} />
    <path d="M12.1494 38.0625L2.08691 55.15L11.5119 56.3375L17.1119 64L27.0994 47.05C20.6994 46.175 15.7494 43.125 12.1494 38.0625Z" fill={iconColor} />
    <path d="M51.8496 38.0625L61.9246 55.15L52.4996 56.3375L46.8996 64L36.9121 47.05C43.2996 46.175 48.2496 43.125 51.8496 38.0625Z" fill={iconColor} />
  </svg>
);

Brand.defaultProps = {
  iconColor: 'white',
  width: '64',
  height: '64'
};

export default Brand;
