import axiosInstance from '../../../core/network';
import {
  LoginFormBody,
  ResetPasswordFormBody,
  CreatorSignUpFormBody,
  BrandSignUpFormBody,
  UpdatePasswordFromBody
} from '../utilites';

export function loginRequest(body: LoginFormBody) {
  return axiosInstance.post('/auth/login', body);
}

export function signUpRequest(
  body: CreatorSignUpFormBody | BrandSignUpFormBody, type: string
) {
  return axiosInstance.post(`/auth/${type}`, body);
}

export function resetPasswordRequest(
  body: ResetPasswordFormBody,
) {
  return axiosInstance.post(`/auth/resetPassword`, body);
}

export function updatePasswordRequest(payload: UpdatePasswordFromBody) {
  return axiosInstance.patch('/auth/password', {
    ...payload
  });
}

export function verifyToken(token: string) {
  return axiosInstance.get(`/auth/verify/${token}`);
}

export function getBrandList() {
  return axiosInstance.get('/auth/get-brands');
}
