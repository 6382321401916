import { AxiosResponse } from 'axios';

import { 
  assignUserAPI, 
  getCreatorList, 
  updateUserStatusAPI, 
  deleteReviewAPI 
} from './api';
import { AppDispatch } from '../../../../store/store';
import { CREATOR_ACTIONS } from '../../../creator/brands/store/actions';
import { getAlertType } from '../../../../shared/helpers';
import { ActionDispatchType } from '../../../../shared/utilities/interfaces';
import { CORE_ACTIONS } from '../../../../core/store/app/actions';
import { getBrandListAPI } from 'features/admin/dashboard/store/api';
import { getReviewsAPI, updateReviewStatusAPI } from 'features/admin/dashboard/store/api';
import { getFromSession } from 'shared/helpers/auth.helper';
import { SessionKeys } from 'core/constants';

export const GET_ADMIN_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_CREATOR_LIST = 'GET_CREATOR_LIST';
export const GET_CREATOR_LIST_FAILURE = 'GET_CREATOR_LIST_FAILURE';
export const SET_USERS_COUNT = 'SET_USERS_COUNT';
export const GET_BRAND_LIST = 'GET_BRAND_LIST';
export const SET_BRAND_COUNT = 'SET_BRAND_COUNT';
export const GET_BRAND_LIST_FAILURE = 'GET_CREATOR_LIST_FAILURE';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const GET_REVIEW_LIST = 'GET_REVIEW_LIST';
export const SET_REVIEW_COUNT = 'SET_REVIEW_COUNT';
export const GET_REVIEW_LIST_FAILURE = 'GET_REVIEW_LIST_FAILURE';
export const ASSIGN_THE_BRAND = 'ASSIGN_THE_BRAND';

export function GetAdminDashboard() {
  return (dispatch: AppDispatch) => {
    // perform actions
    dispatch({
      type: GET_ADMIN_DASHBOARD_DATA,
      payload: {
        // add payload here
      },
    });
  };
};


export function GetCreatorList(searchString, page) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse = await getCreatorList(searchString, page);
      const { data, count, keys } = response.data.data;
      if (data.length > 0) {
        dispatch({
          type: GET_CREATOR_LIST,
          payload: { data, keys },
        });
        dispatch({
          type: SET_USERS_COUNT,
          payload: count
        });
      } else {
        dispatch({
          type: GET_CREATOR_LIST_FAILURE
        });
      }
    } catch (e) {
      dispatch({
        type: GET_CREATOR_LIST_FAILURE
      });
    }
  };
};

export function GetBrandList(searchString, page) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse = await getBrandListAPI(searchString, page);
      const { data, count, keys } = response.data.data;
      if (data.length > 0) {
        dispatch({
          type: GET_BRAND_LIST,
          payload: { data:[], keys },
        });
        dispatch({
          type: GET_BRAND_LIST,
          payload: { data, keys },
        });
        dispatch({
          type: SET_BRAND_COUNT,
          payload: count
        });
      }
      else {
        dispatch({
          type: GET_BRAND_LIST_FAILURE,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_BRAND_LIST_FAILURE
      });
    }
  };
};

export function GetReviewsList(searchString, page, userId) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse =
        await getReviewsAPI(searchString, page, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_REVIEW_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_REVIEW_COUNT,
        payload: count
      });
    } catch (e) {
      dispatch({
        type: GET_REVIEW_LIST_FAILURE
      });
    }
  };
};

export function searchCreators(searchString) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse = await getCreatorList(searchString, 0);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_CREATOR_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_USERS_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}


export function searchBrands(searchString) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse = await getBrandListAPI(searchString, 0);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_BRAND_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_BRAND_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}

export function updateUserStatus(
  status: string, userId: string, type: string,
  searchString: string, page: number
) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    try {
      const response: any = await updateUserStatusAPI(status, userId);
      if (response.data.code == 200) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code)
          }
        });
      };
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
      if (type === "creator") {
        dispatch(GetCreatorList(searchString, page - 1));
      }
      else {
        dispatch(GetBrandList(searchString, page - 1));
      }
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}
export function updateReviewStatus(
  status: string, userId: string, reviewId: string,
  type: string, searchString: string, page: number
) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    try {
      const adminId = getFromSession(SessionKeys.userId).result;
      let response: any = {};
      if(status === 'DELETE') response = await deleteReviewAPI(adminId, reviewId);
      else response = await updateReviewStatusAPI(status, userId, reviewId);
      if (response.data.code == 200) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code)
          }
        });
      };
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
      dispatch(GetReviewsList(searchString, page - 1, adminId));
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}


export function searchReviews(searchString, userId) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse =
        await getReviewsAPI(searchString, 0, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_REVIEW_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_REVIEW_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}

export function assignBrand(
  userId: string, brandId: string, searchString: string, page: number
) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    try {
      const response: any = await assignUserAPI(userId, brandId);
      if (response.data.code == 200) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code)
          }
        });
      };
      dispatch(GetBrandList(searchString, page - 1));
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}