import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { openRoutes } from './routes';
function PublicRoutes() {
	return (
		<Fragment>
			<Switch>
			  {openRoutes.map(singleRoute => {
           return (<Route
            component={singleRoute.component}
            exact={singleRoute.exact}
            path={singleRoute.path}
            key={singleRoute.path}
          />);
           })   
        }
	   	</Switch>
		</Fragment>
	);
}

export default PublicRoutes;
