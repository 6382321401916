import React from 'react';
import {
	AppBar,
	Avatar,
	IconButton,
	Menu,
	MenuItem,
	Toolbar
} from '@mui/material';
import { Box, styled } from '@mui/system';
// import Brightness2 from '@mui/icons-material/Brightness2';
// import Brightness4 from '@mui/icons-material/Brightness4';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { toggleAppTheme } from '../../store/app/actions';
import { IApp, IAuth, IState } from '../../../shared/utilities/interfaces';
import { AuthRoles, SessionKeys } from '../../../core/constants';
import Apptheme from '../../theme/appTheme';
import ExitIcon from '../../../assets/exit_Icon.svg';
import { getFromSession } from 'shared/helpers/auth.helper';
import { logoutAction } from '../../../features/auth/store/actions';
import { HeaderLogo } from 'shared/components/icons';

function getSessionValue(key: string) {
	const { result, success } = getFromSession(key);
	return success ? result : '';
}

const RouterLink = styled(Box)(({ theme }) => ({
	margin: '0 1rem 0 0',
	fontSize: '14px',
	fontWeight: 'bold',
	height: '4rem',
	width: '100px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	verticalAlign: 'middle',
	'a': {
		color: 'black',
		textDecoration: 'none',
	},
	'.border': {
		backgroundColor: theme.palette.primary.main,
		width: '100px',
		height: '4px',
		position: 'absolute',
		borderRadius: '12px 12px 0 0',
		bottom: '0'
	}
}));

const HeaderContainer = styled(AppBar)(({ theme }) => {
	return {
		backgroundColor: theme.palette.common.white,
		borderRadius: '0',
		zIndex: theme.zIndex['appBar'],
		boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)'
	};
});
interface IHeader {
	app: IApp;
	// eslint-disable-next-line no-unused-vars
	changeAppTheme: (theme: string) => void;
	auth: IAuth;
	logout: () => void;
}

const Header = (HeaderProps: IHeader) => {
	const { pathname } = useLocation();
	const theme = Apptheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { auth, logout } = HeaderProps;
	const { accountType, isLoggedIn, userData, status } = auth;
	const open = Boolean(anchorEl);
	const brandId = getSessionValue(SessionKeys.brandId);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	let routerLinks = [];
	if (accountType === AuthRoles.CREATOR) {
		routerLinks = [
			{
				to: '/dashboard',
				text: 'Home'
			},
			{
				to: '/brands',
				text: 'Brands'
			},
			{
				to: '/creator-profile',
				text: 'Profile'
			}
		];
	} else if (accountType === AuthRoles.BRAND) {
		routerLinks = [
			{
				to: `/brand-profile/${brandId}`,
				text: 'Profile'
			}
		];
	} else if (accountType === AuthRoles.ADMIN) {
		routerLinks = [
			{
				to: `/manage-creators`,
				text: 'Creators'
			},
			{
				to: `/manage-brands`,
				text: 'Brands'
			},
			{
				to: `/manage-reviews`,
				text: 'Reviews'
			},
		];
	}
	if (isLoggedIn && status === 'APPROVED') {
		return (
			<>
				<Box sx={{ flexGrow: 1 }}>
					<HeaderContainer
						position='fixed'
					>
						<Toolbar>
							<Box 
							sx={{flexGrow: 1}}
							>
								<div style={{
									overflow: "hidden",
									height: "36px",
									width: "70px",
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									}}>
									<HeaderLogo/>
								</div>
							</Box>
							<Box
								sx={{ flexGrow: 6 }}
								style={{ display: 'flex', position: 'absolute', left: '200px' }}
							>
								{routerLinks.map((item) => {
									return (
										<RouterLink key={item.to} style={{ margin: '0' }}>
											<Link
												to={item.to}
												style={{
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													width: '100%',
													justifyContent: 'center',
												}}
											>{item.text}</Link>
											{pathname.includes(item.to) &&
												<div className="border"></div>
											}
										</RouterLink>
									);
								})}
							</Box>
							{/* <IconButton>
								<NotificationsActiveIcon
									sx={{ color: 'black' }}
								/>
							</IconButton> */}
							{/* <IconButton onClick={changeTheme}>
								{
									themeMode === 'light'
							? <Brightness2 sx={{ color: 'black' }} />
							: <Brightness4 sx={{ color: 'black' }} />
								}
							</IconButton> */}
							<Box
								onClick={handleClick}
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Avatar
									sx={{ bgcolor: theme.palette.primary.main }}
									style={{
										width: '28px',
										height: '28px',
										fontSize: '14px',
										cursor: 'pointer',
										textTransform: 'capitalize'
									}}
									onClick={handleClick}
								>
									{userData.name[0]}
								</Avatar>
								<IconButton
									aria-label="more"
									id="long-button"
									aria-controls="long-menu"
									aria-expanded={open ? true : null}
									aria-haspopup="true"
									onClick={handleClick}
									style={{
										padding: '0',
										margin: '0 0 0 3px',
									}}
								>
									<ArrowDropDownIcon style={{
										color: 'rgba(0, 0, 0, 0.26)'
									}} />
								</IconButton>
							</Box>
							<Menu
								sx={{
									mt: '12px'
								}}
								id="long-menu"
								MenuListProps={{
									'aria-labelledby': 'long-button',
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								PaperProps={{
									style: {
										borderRadius: '0px 0px 8px 8px',
										maxHeight: 48 * 4.5,
										width: '20ch',
										boxShadow: '0px 5px 6px -3px rgba(35, 47, 52, 0.2), 0px 9px 12px 1px rgba(35, 47, 52, 0.14)',
									},
								}}
							>
								<MenuItem
									sx={{
										fontSize: '12px',
										color: '#1A1A1A'
									}}
									onClick={() => {
										handleClose();
										logout();
									}}
								>
									<img src={ExitIcon} style={{ marginRight: '8px' }} /> Logout
								</MenuItem>
							</Menu>
						</Toolbar>
					</HeaderContainer>
				</Box>
			</>
		);
	} else {
		return <></>;
	}
};

export default connect(
	(state: IState) => ({
		app: state.app,
		auth: state.auth
	}),
	{
		changeAppTheme: toggleAppTheme,
		logout: logoutAction
	}
)(Header);
