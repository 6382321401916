import { IBrandFollowers, IBrands, ISelectedBrand, ReducerActionProps, ReviewList } from '../../../../shared/utilities/interfaces';
import {
  CREATOR_ACTIONS
} from './actions';

const initialState: {
  searchLoading: boolean;
  brandsList: ISelectedBrand[];
  searchList: ISelectedBrand[];
  selectedBrand: ISelectedBrand;
  updatedProfilePic: string;
  reviewsList: ReviewList[];
  brandFollowers: IBrandFollowers[];
  followedList: IBrands[];
} = {
  searchLoading: false,
  brandsList: [],
  searchList: [],
  selectedBrand: {
    aboutMeDescription: "",
    brandImage: "",
    brandName: "",
    createdAt: "",
    followers: 3,
    socialMedia: [
      { noOfFollowers: 0, link: '', socialMediaName: '', userName: '', _id: '' }
    ],
    status: "",
    priceRange: {
      min: 0,
      max: 0,
    },
    isFollowing: false,
    suggesterID: "",
    updatedAt: "",
    website: "",
    __v: 0,
    _id: ""
  },
  reviewsList: [],
  brandFollowers: [],
  followedList: [],
  updatedProfilePic: '',
  // add initial state variables
};

const brandsReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case CREATOR_ACTIONS.GET_BRANDS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case CREATOR_ACTIONS.SEARCH_BRANDS_SUCCESS:  
      return {
        ...state,
        ...action.payload,
        searchLoading: false,
      };
    case CREATOR_ACTIONS.GET_BRAND_DATA_SUCCESS:
    case CREATOR_ACTIONS.GET_FOLLOWED_BRANDS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case CREATOR_ACTIONS.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case CREATOR_ACTIONS.GET_BRAND_FOLLOWERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CREATOR_ACTIONS.GET_FOLLOWED_BRANDS_FAILURE:
      return {
        ...state,
        followedList: []
      };
    case CREATOR_ACTIONS.GET_BRANDS_FAILURE:
      return {
        ...state,
        brandList: []
      };
    case CREATOR_ACTIONS.SEARCH_BRANDS_FAILURE:
      return {
        ...state,
        searchList: [],
        searchLoading: false,
      };
    case CREATOR_ACTIONS.GET_REVIEWS_FAILURE:
      return {
        ...state,
        reviewsList: []
      };
    case CREATOR_ACTIONS.GET_BRAND_FOLLOWERS_FAILURE:
      return {
        ...state,
      };
    case CREATOR_ACTIONS.SEARCH_BRANDS_REQUEST:
      return {
        ...state,
        searchLoading: true
      };
    case CREATOR_ACTIONS.START_INLINE_LOADER:
      return {
        ...state,
        searchLoading: true
      };
    case CREATOR_ACTIONS.STOP_INLINE_LOADER:
      return {
        ...state,
        searchLoading: false
      };
    case CREATOR_ACTIONS.SET_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        updatedProfilePic: action.payload.brandImage
    };
    case CREATOR_ACTIONS.RESET_ADD_BRAND_PROFILE_PIC:
      return {
        ...state,
        updatedProfilePic: ''
    };
    default:
      return {
        ...state
      };
  }
};

export default brandsReducer;
