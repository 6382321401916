import axiosInstance from '../../../../core/network';
import { getFromSession } from '../../../../shared/helpers/auth.helper';
import { SessionKeys } from '../../../../core/constants';

export function getAllBrands(body) {
	return axiosInstance.post(
		`brand/${getFromSession(SessionKeys.userId).result}/getBrands`, body
	);
}

export function getBrandData(brandId: string) {
	return axiosInstance.get(`brand/${getFromSession(SessionKeys.userId).result}/${brandId}`);
}

export function getReviewsApi(
	brandId: string,
	limit: string,
	offset: string,
	body: {
		followers?: {
			min?: number,
			max?: number;
		},
		socialMediaName?: string;
	}) {
	const url = parseInt(limit) == 0 ? `brand/${getFromSession(SessionKeys.userId).result}/brand/${brandId}/review`
	: `brand/${getFromSession(SessionKeys.userId).result}/brand/${brandId}/review?limit=${limit}&skip=${offset}`;
	return axiosInstance.get(
		url, {
		params: {
			...body
		}
	}
	);
}

export function getBrandFollowingUsers(brandId: string) {
	return axiosInstance.get(
		`follow/${getFromSession(SessionKeys.userId).result}/brand/${brandId}`
	);
}
export function getFollowedBrands() {
	return axiosInstance.get(`follow/${getFromSession(SessionKeys.userId).result}/brand`);
}

export function followBrand(body: {
	brand_id: string;
	user_id: string;
	isFollower: boolean;
}) {
	return axiosInstance.post(`follow/${getFromSession(SessionKeys.userId).result}/follow`, body);
}

export function addReview(body) {
	const { brandId, reviewerId } = body;
	return axiosInstance.post(`brand/${reviewerId}/brand/${brandId}/review`, body);
}

export function suggestBrand(body, userId) {
	return axiosInstance.post(`brand/${userId}/brand`, body);
}
