import React from 'react';

const Notes = ({ height, width, iconColor }) => (
  <svg width={width} height={height} viewBox="0 0 84 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3332 18.5H6.1665V61.6666C6.1665 65.0583 8.9415 67.8333 12.3332 67.8333H55.4998V61.6666H12.3332V18.5ZM61.6665 6.16663H24.6665C21.2748 6.16663 18.4998 8.94163 18.4998 12.3333V49.3333C18.4998 52.725 21.2748 55.5 24.6665 55.5H61.6665C65.0582 55.5 67.8332 52.725 67.8332 49.3333V12.3333C67.8332 8.94163 65.0582 6.16663 61.6665 6.16663ZM58.5832 33.9166H27.7498V27.75H58.5832V33.9166ZM46.2498 46.25H27.7498V40.0833H46.2498V46.25ZM58.5832 21.5833H27.7498V15.4166H58.5832V21.5833Z" fill={iconColor}/>
    <path d="M63 63.2501V67.0001H66.75L77.81 55.9401L74.06 52.1901L63 63.2501ZM80.71 53.0401C81.1 52.6501 81.1 52.0201 80.71 51.6301L78.37 49.2901C77.98 48.9001 77.35 48.9001 76.96 49.2901L75.13 51.1201L78.88 54.8701L80.71 53.0401Z" fill={iconColor}/>
  </svg>
);

Notes.defaultProps = {
  iconColor: '#4211CF',
  width: '84',
  height: '74'
};

export default Notes;
